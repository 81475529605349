.arrow{
    position: fixed;
    /* font-Size: 15px; */
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius:50%;
    width: 30px;
    height: 30px;
    bottom: 40px;
    left:30px;
    background-color: var(--dgi-theme-primary);
    color: #fff;
    box-shadow: 0px 5px 10px 0px  #00000056;
    -webkit-animation: simple-scale 1s 1s linear infinite alternate;
    animation: simple-scale 1s 1s linear infinite alternate;
    cursor: pointer;
    z-index: 33;
    overflow: hidden;
}



.arrow:hover{
    background-color:var(--dg--hover-rgb);
}

@-webkit-keyframes simple-scale {
    0%{
        transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.2);
    }
  }
  
  @keyframes simple-scale {
    0%{
        transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }

  .arrow:hover svg{
    -webkit-animation: bounce 2s ease-in-out infinite alternate;
  }
  @-webkit-keyframes bounce  {
    0% {
      text-shadow:
                  0 5px 0 #ccc,
                  0 2px 3px rgba(0, 0, 0, 1);  
  }
  50%{
    transform:translateY(-50px);
  }
  100% {
    transform:translateY(-100px);
    text-shadow:  0 50px 0 black,
                  0 0px 20px rgba(0, 0, 0, .8);
                  opacity: 0;
  }
}

@media (max-width:600px) {
    .arrow{
        position: fixed;
        /* font-Size: 15px; */
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius:50%;
        width: 20px;
        height: 20px;
        bottom: 20px;
        left:10px;
        background-color: var(--dgi-theme-primary);
        color: #fff;
        box-shadow: 0px 5px 10px 0px  #00000056;
        -webkit-animation: simple-scale 1s 1s linear infinite alternate;
        animation: simple-scale 1s 1s linear infinite alternate;
        cursor: pointer;
    }
}