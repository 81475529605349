/*  */
.worldslecet {
    /* Reset Select */
    appearance: none;
    outline: 0;
    border: 0;
    box-shadow: none;
    /* Personalize */
    flex: 1;
    padding: 0 1em;
    color: #fff;
    border: 1px solid #fff1ff;
    background-color:transparent;
    background-image: none;
    cursor: pointer;
  }
  /* Remove IE arrow */
  .worldslecet::-ms-expand {
    display: none;
  }
  /* Custom Select wrapper */
  .worldslecet {
    position: relative;
    display: flex;
    max-width: 70px;
    border-radius: .25em;
    overflow: hidden;
  }
  /* Arrow */
  .worldslecet::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    background-color:#333e;
    transition: .25s all ease;
    pointer-events: none;
  }
  /* Transition */
  .worldslecet:hover::after {
    color: #f39c12;
  }
  
.emailinput {
    max-width: 380px;
    position: relative;
}

.emailinput input{
    border-radius: 30px;
    border: 1px solid #ffffff !important;
    background: transparent;
    color: rgba(253, 253, 253, 0.933);
    font-size: 1.2rem;
    width: 100%;
}

.emailinput button{
    position: absolute;
    right: 10px;
    top:10px;
    bottom:10px;
    /* background-image: -webkit-linear-gradient(-18deg, rgb(60, 114, 252) 0%, rgb(38, 64, 141) 78%, rgb(15, 13, 29) 100%); */
    background: var(--dg--hover-rgb);
    border-radius: 30px;
    font-size: 1rem;
    letter-spacing:0.5px;
    font-weight: 500;
    width: 100px;
    color: azure;
    box-shadow: 0px 2px 5px #333e ;
    cursor: pointer;
    transition: all .2s ease;
}
.emailinput button:hover{
  background-color:#153651;
}
.header2{
    display: none;
  
}
.active{
    display: block;
}

.Selectwid{
  max-width: 70px;
  height: 30px;
}